// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-download-report-js": () => import("./../src/pages/DownloadReport.js" /* webpackChunkName: "component---src-pages-download-report-js" */),
  "component---src-pages-home-js": () => import("./../src/pages/Home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-lets-fintech-js": () => import("./../src/pages/LetsFintech.js" /* webpackChunkName: "component---src-pages-lets-fintech-js" */),
  "component---src-pages-media-js": () => import("./../src/pages/Media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-startups-js": () => import("./../src/pages/Startups.js" /* webpackChunkName: "component---src-pages-startups-js" */)
}

